.award-image {
    width: 100%;
    height: 600px;
    max-height: 70vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;

/*    &.award-image-level-Bronze {
        background-image: url("./2022/TCS_Award_Bronze_2022_RGB.png");
    }

    &.award-image-level-Silver {
        background-image: url("./2022/TCS_Award_Silver_2022_RGB.png");
    }

    &.award-image-level-Gold {
        background-image: url("./2022/TCS_Award_Gold_2022_RGB.png");
    }

    &.award-image-level-NoAward {
        background-image: url("./2022/TCS_Award_NoAward_2022_RGB.png");
    }*/

    &.award-image-size-lg {
        height: 800px;
    }

    &.award-image-size-sm {
        height: 300px;
    }
}
