@import "../../styles/_colors.scss";

.actions {
    .scroll-to-active {
        > .card {
            border-color: $secondary !important;
        }
    }
    // Get some space around the headings.
    .actions-column-heading {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        cursor: pointer;
    }

    .actions-column {
        margin-bottom: 1.5rem;
    }
}
