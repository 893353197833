@import "../../styles/_colors.scss";

.generate-card {
    // Sub headings need to look more important.
    .generate-card-sub-heading {
        font-size: 1rem;
        padding-top: 0.3rem;
        padding-bottom: 0.6rem;
    }
    
    .generate-card-help-container {
        // Want a border line at the bottom as a seperator.
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: $light-gray;
        margin-bottom: 0.4rem;
    }

    .generate-card-type-container {
        padding-bottom: 0.4rem;
        // Want a border line at the bottom as a seperator.
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: $light-gray;
        margin-bottom: 0.4rem;
    }

    .generate-card-areas-container {
        // Want a border line at the bottom as a seperator.
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: $light-gray;
        margin-bottom: 0.4rem;
        // Each area needs to show as a seperate item.
        .generate-card-area {
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-bottom-color: $light-gray;
            margin-bottom: 0.4rem;
        }
    }

    .generate-card-progress-container {
        // Formatting of the label within the custom progress bar
        .generate-card-progress-label {
            display: flex;
            justify-content: center;
            text-align: center;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            padding-top: 33px;
            user-select: none;
            font-size: 20px;
        }
    }
}
