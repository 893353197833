.rounded-button-group {
    .btn:first-child {
        border-top-left-radius: 20px !important;
        border-bottom-left-radius: 20px !important;
    }

    .btn:last-child {
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
    }
}
