.school-summary-card {
    .school-summary-card-header {
        background-color: transparent !important;
    }
    // Format the action buttons so they look good on different screen sizes.
    .school-summary-action-button {
        width: 100%;
    }
}


