@import "../../styles/_colors.scss";
@import "../../styles/_breakpoints.scss";

.action-card {
    // Lightly colour the background of items to match their states and borders.
    &.action-card-danger {
        .card-header {
            background-color: $danger !important;
            color: $dark-color !important;
        }
    }

    &.action-card-warning {
        .card-header {
            background-color: $warning !important;
            color: $gray-800 !important;
        }
    }

    &.action-card-success {
        .card-header {
            background-color: $success !important;
            color: $dark-color !important;
        }
    }

    &.action-card-dark {
        .card-header {
            background-color: $dark !important;
            color: $dark-color !important;
        }
    }
    // Manipulate the bottom of the cards to appear more table like.
    margin-bottom: 0px;

    .card-body {
        padding-top: 0.8rem;
        padding-bottom: 0rem;
    }
    // Labels should show gray.
    label {
        color: $gray-700;
        font-weight: bold;
    }
    // If we are not the first card in the group, hide the labels so we are table like (lg up only) and remove the top border.
    &.action-card-not-first-in-group {
        border-top-style: none;

        @include media-breakpoint-up(lg) {
            label {
                display: none;
            }
        }
    }
    // Fix issue where the rounding on the dropdown toggle was only showing if we showed a caret.
    .action-card-menu-dropdown-toggle {
        border-top-right-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;
    }
}
