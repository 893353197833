@import "../../styles/_colors.scss";

.area-compliance-card {
    .area-compliance-card-heading {
        color: $theschoolbus-blue;
        font-weight: bold;
        border-bottom: 1px solid $theschoolbus-rust;
        display: inline-block;
    }

    // Expand option should show in bold if the user has filtered and this area contains some requirements meeting the filter.
    .area-compliance-card-expand-message-with-matches {
        font-weight: bold;
    }
}
