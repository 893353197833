@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";
@import "../../styles/_variables.scss";

.sidebar {
    .sidebar-tsb-menu {
        width: 100%;
        background-color: $white;
        color: $theschoolbus-blue;
        font-size: 14px;
        // Only visible on mobile/sm.
        display: none;

        @include media-breakpoint-down(sm) {
            display: block;
        }

        .sidebar-tsb-menu-heading {
            min-height: 60px;
            padding-top: 20px;
            border-bottom: 1px solid $theschoolbus-lightgray;
            display: flex;

            .sidebar-tsb-menu-heading-back-arrow {
                cursor: pointer;
                padding-left: 20px;
                padding-right: 10px;
            }

            .sidebar-tsb-menu-heading-text {
                width: 100%;
                flex-grow: 2;
                text-align: center;
                text-transform: uppercase;
                padding-right: 20px;
                font-weight: bold;
            }
        }

        .sidebar-tsb-menu-item {
            min-height: 60px;
            display: flex;
            border-bottom: 1px solid $theschoolbus-lightgray;
            padding: 1.25rem;
            cursor: pointer;

            .sidebar-tsb-menu-item-icon {
                height: 22px;
                width: 28px;
                background-size: 22px 22px;
                margin-right: 16px;
                background-repeat: no-repeat;
            }

            .sidebar-tsb-menu-item-text {
                width: 100%;
                flex-grow: 2;
                font-weight: 800;
            }
        }
    }


    .menu-nav {
        min-height: 0px !important; // Overide the min-height: 100% from coreui as we use multiple navs to manage our sidebar.
    }

    @include media-breakpoint-up(md) {
        .menu-nav {
            display: none !important;
        }
    }
    /*// Lighten the icon colours as the primary colour is too dark to look good on the background.
    .nav-link.active {
        .nav-icon {
            color: lighten($primary, 25%);
        }
    }*/

    .sidebar-container {
        flex-grow: 2;
    }

    .sidebar-forbes-logo {
        width: 100%;
        height: 32px;
        background-image: url(../../styles/images/tcs-logo/forbes.png);
        background-size: contain;
        background-position-x: center;
        background-position-y: bottom;
        margin-bottom: 10px;
        background-repeat: no-repeat;
    }
    // Resize to meet shorter navbar when the categories bar is removed.
    @media (max-width: $collapse-categories-max-width) {
        height: calc(100vh - 89px) !important;
    }
}


// On mobile, fill the whole screen width to match TSB design of new header.
@include media-breakpoint-down(sm) {
    .sidebar-show {
        .sidebar {
            width: 100%;
            min-width: 100%;

            .sidebar-nav {
                width: 100%;
                min-width: 100%;
            }

            .nav {
                width: 100%;
                min-width: 100%;
            }
        }
    }
}
