// Colors
//
// These colours can be included by any scss files for components if required.
// They also get included in in the main imports for bootstrap and coreui by the layout to override their colours.

$theschoolbus-blue: #041e42;
$theschoolbus-rust: #d6410a;
$theschoolbus-aquamarine: #47d7ac;
$theschoolbus-white: #ffffff;
$theschoolbus-lightgray: #ececec;
$theschoolbus-darkgray: #7C7C7C;

// Award colours.
$bronze: #f0b26c;
$bronze-dark: #bf692a;
$silver: #bababa;
$silver-dark: #5c5c5c;
$gold: #d0aa3f;
$gold-dark: #91630f;

// Main colours for the app's theme.
$primary: $theschoolbus-rust;
$secondary: $theschoolbus-blue;
$success: #28a745;
$info: #63c2de;
$warning: #ffc107;
$danger: #dc3545;
$light: $theschoolbus-white;
$dark: $theschoolbus-blue;

// Useful monochrome colours.
$white: #fff;
$black: #000;
$gray-base: #181b1e;
$gray-100: lighten($gray-base, 85%);
$gray-200: lighten($gray-base, 80%);
$gray-300: lighten($gray-base, 70%);
$gray-400: lighten($gray-base, 60%);
$gray-500: lighten($gray-base, 50%);
$gray-600: lighten($gray-base, 40%);
$gray-700: lighten($gray-base, 30%);
$gray-800: lighten($gray-base, 10%);
$gray-900: lighten($gray-base, 5%);


// Explict light and dark pairs.
$dark-bg: $theschoolbus-blue;
$dark-color: $white;
$light-bg: $white;
$light-color: $gray-800;

// Colours pairs for main areas.
$body-bg: $theschoolbus-lightgray;
$body-color: $gray-900;
$main-bg: $white;
$main-color: $gray-900;


// Legacy colours referenced in multiple places before moving to the new branding.
$light-gray: $gray-400;
$navbar-link-gray: $gray-700;
