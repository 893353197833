@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.main-container {
    background-color: $main-bg;
    color: $main-color;

    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

@include media-breakpoint-up(sm) {
    .main-container {
        //margin-top: 10px;
        border-radius: 10px 10px;

        &.container-fluid {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }
    }
}
