.watch-video-modal {
    .watch-video-modal-close {
        float: right;
        font-size: 1.4rem;
        margin-top: -19px;
        cursor: pointer;
    }

    .watch-video-modal-name {
        font-weight: bold;
        margin-bottom: 8px;
    }
}
